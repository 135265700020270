<template>
    <v-app>
        <v-container fill-height fluid style="background-color:gray">
            <v-row align="center"
                   justify="center">
                <v-col cols="6">
                    <v-form ref="form"
                            lazy-validation
                            v-on:keyup.native.enter="checkUtente">
                        <v-card class="elevation-24 rounded-lg">
                            <v-row class="mt-0">
                                <v-col cols="4" offset="4" align="center">
                                    <div v-if="theme">
                                        <v-img v-if="$store.state.conf.isService" src="@/assets/servicepro_login_dark.png"
                                               contain
                                               max-width="300" max-height="250">
                                        </v-img>
                                        <v-img v-else src="@/assets/studiopro_login_dark.png"
                                               contain
                                               max-width="300" max-height="250">
                                        </v-img>
                                    </div>
                                    <div v-else>
                                        <v-img v-if="$store.state.conf.isService" src="@/assets/servicepro_login.png"
                                               contain
                                               max-width="300" max-height="250">
                                        </v-img>
                                        <v-img v-else src="@/assets/studiopro_login.png"
                                               contain
                                               max-width="300" max-height="250">
                                        </v-img>
                                    </div>
                                    <span class="text-caption grey--text">Client VRM {{$store.getters.appVersion }}</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" offset="3" align="center">
                                    <v-text-field v-model="username"
                                                  label="Email"
                                                  :rules="[v => !!v || 'campo obbligatorio', v => /.+@.+\..+/.test(v) || 'inserire un email valida', v => message!='0' || 'email o password errate', v => message!='d' || 'utente non abilitato', v => message!='1' || 'licenza software scaduta' ]"
                                                  placeholder="Inserisci l'email di registrazione"
                                                  outlined
                                                  dense
                                                  required>
                                    </v-text-field>
                                    <v-text-field v-model="password"
                                                  label="Password"
                                                  :rules="[v => !!v || 'campo obbligatorio',v => v.length >= 4 || 'minimo 4 caratteri']"
                                                  :type="show1 ? 'text' : 'password'"
                                                  placeholder="Inserisci la password"
                                                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                  @click:append="show1 = !show1"
                                                  outlined
                                                  dense
                                                  required>
                                    </v-text-field>

                                    <v-btn :loading="loading" class="mb-4" depressed @click="checkUtente"
                                           color="blue darken-2" dark>
                                        Login
                                    </v-btn>
                                    <div v-if="$store.state.isLocked">
                                        <span class="text-caption">
                                            <a :href="$store.state.lplApp.linkPagamento">Clicca qui per rinnovare</a>
                                        </span>
                                    </div>
                                    <template>
                                        <v-progress-linear v-if="progressValue>0" :value="progressValue"></v-progress-linear>
                                    </template>
                                    <div v-if="enableNew">
                                        <span class="text-caption">Non sei registrato?
                                            <a @click="wizard">Clicca qui</a>
                                        </span>
                                    </div>
                                </v-col>                                    
                            </v-row>                          
                        </v-card>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
        
    </v-app>
</template>

<script>

    import { callPost, callService, gSynch1, gSynch2, gSynch3, gSynch4, gSynch5 } from '@/modules/utilities.js'

    export default {
        name: 'Login',
        data() {
            return {
                username: '',
                show1: false,
                password: '',
                message: '',
                loading: false,
                progressValue: 0,
                enableNew: false
            }
        },
        computed: {
            theme() {
                return this.$cookie.get('theme') == "true";
            }
        },
        methods: {
            wizard() {
                this.$router.replace({ name: "wizard" });
            },
            async checkUtente() {

                this.message = '';

                if (!this.$refs.form.validate()) return;

                this.loading= true;

                let paramJson={
                        username: this.username,
                        password: this.password
                };

                this.progressValue='10';

                const output = await callPost('api/utente', paramJson,true);

                this.progressValue='40';

                if(output==null){
                    this.message = '0';
                    this.$refs.form.validate();
                    this.loading = false;     
                    this.progressValue = '0';
                }
                else {
                    let utente = output.utente;
                    let admin = output.admin;
                    let disabilitato = output.disabilitato;

                    if (disabilitato) {
                        this.message = 'd';
                        this.$refs.form.validate();
                        this.loading = false;
                        this.progressValue = '0';
                        return;
                    }                  

                    //imposto lo stato                    
                    this.$store.dispatch('setPartition',utente._partition);
                    this.$store.dispatch('setUtente',utente);
                    this.$store.dispatch('setAdmin',admin);                   
                    let param = [];
                    let annoContabile = await callService("api/annoContabile", param);

                    this.$store.dispatch('setIDAnnoContabile',annoContabile.id);
                    this.$store.dispatch('setAnno',annoContabile.anno);
                    this.$store.dispatch('setAnnoAperto',annoContabile.isAperto);

                    //carico tabelle di conoscenza
                    await gSynch1();      
                    //controllo della licenza
                    if(this.$store.state.isLocked) {
                        this.message = '1';
                        this.$refs.form.validate();
                        this.loading = false;     
                        this.progressValue = '0';
                        return;
                    }                  

                    //
                    this.progressValue = '50';
                    await gSynch2();
                    this.progressValue='65';
                    await gSynch3();
                    //carico elenco utenti
                    this.progressValue = '75';                    
                    await gSynch4();
                    this.progressValue='85';
                    await gSynch5();

                    this.$store.state.sessionID = utente.username + new Date().toISOString(); //SessionID creato temporaneamente cos�

                    this.progressValue='100';

                    let login=this;  
                    setTimeout(function() {
                        login.$store.dispatch('login');
                        login.$router.replace({ name: "dashboard" });
                        login.$updaterHub.$emit('notifiche-refresh', {});

                    }, 200);                    
                };
            }
        },
        async created() {
            let param = [];
            let conf = await callService("api/confSite", param, true);
            this.$store.state.conf = conf;
        },
    }</script>
